import { batch, Component, createMemo, createSignal, JSX, onCleanup, onMount, Show } from "solid-js"
import './IsleOfCats.scss';
import { Pool } from "@/components/Pool/Pool";
import { Game, Piece, PiecePlacement, Player } from "./objects";
import { Board } from "@/components/Board/Board";
import { MakeMoveRequest, PassMoveRequest, PossibilitiesRequest, Request, RequestType } from "./request";
import { Response, ResponseType } from "./response";
import { GameProps } from "../baseGame/componentBase";
import { PlayerType, Position, Possibility } from "../baseGame/objects";
import { NewGameButton } from "@/components/NewGameButton/NewGameButton";
import { Button } from "@/components/ui/button";
import { TileStyle } from "@/components/Tile/types";
import { BOARD_STYLE, getPieceStyle, getPossibilityStyle, getResourceStyle, getRoomStyle } from "../baseGame/constants";
import { Tile } from "@/components/Tile/Tile";

export type IsleOfCatsProps = GameProps<Game, Request, Response>


export const IsleOfCats: Component<IsleOfCatsProps> = (props) => {
  /*********
  * Inputs *
  **********/
  const schema = () => props.schema
  const defaultSettings = () => props.defaultSettings
  const sendMessage = props.sendMessage

  /************
  * Variables *
  *************/
  const [playerContainerRef, setPlayerContainerRef] = createSignal<HTMLElement>()
  const [game, setGame] = createSignal<Game>(props.initGame)
  const [maxWidth, setMaxWidth] = createSignal(window.innerWidth/2)

  /*********************
  * Computed variables *
  **********************/
  const isGameEnded = createMemo<boolean>(() => {
    return game().ended
  })

  const currentPlayer = createMemo<Player>(() => {
    return game().players.find((x) => x.id === game().current_turn)
  })

  const isMyTurn = createMemo<boolean>(() => {
    return !isGameEnded() && currentPlayer().type === PlayerType.human
  })

  /**********
  * Helpers *
  ***********/
  const poolJsx = () => {
    // Remove duplicate common pieces
    const firstTreasure = {}
    for (let piece of game().pool.pieces) {
      if (piece.type !== "cat" && !firstTreasure[piece.shape.name]) {
        firstTreasure[piece.shape.name] = piece.id
      }
    }
    const treasuresToShow = Object.values(firstTreasure)
    let poolPieces = game().pool.pieces.filter((x) => x.type !== "cat" ? treasuresToShow.includes(x.id): x)
    poolPieces.sort((a, b) => {
      const _x = (x: Piece["type"]) => {
        if (x === "cat" ) {
          return 0
        }
        else if (x === "rare_treasure") {
          return 1
        }
        else {
          return 2
        }
      }
      return _x(a.type) - _x(b.type)
    })

    const poolPiecesWithTiles = poolPieces.map((piece) => {
      return {
        ...piece,
        tile: <Tile grid={piece.shape.grid} style={getPieceStyle(piece.color)} />
      }
    })
    const poolProps = {
      ...game().pool, pieces: poolPiecesWithTiles
    }

    return (
      <div class="flex justify-center" style="min-width: 600px;">
        <div class='flex p-4 pt-1 max-h-64 overflow-auto'>
          <Pool class="" disabled={!isMyTurn()} orientation='horizontal' pool={poolProps} disabledPieces={currentPlayer().disabled_pieces} selectedPiece={selectedPiece()} onPieceSelect={onPoolPieceSelection} />
        </div>
      </div>
    )
  }

  const playerLayoutJsx = (playerId: string) => {
    const player = game().players.find((x) => x.id === playerId)

    const roomTiles = player.board.rooms.map((room) => {
      return [{
        position: room.position,
        tile: <Tile grid={room.shape.grid} style={{...getRoomStyle(room.color)}} disconnected={true} />
      }, {
        position: room.position,
        tile: <Tile grid={room.shape.grid} style={{color: "transparent", border: {color: "gray", width: 0.03}, borderRadius: 0.125}} />
      }]
    }).flat()

    const resourcePlacements = player.board.resource_placements.map((placement) => {
      return {
        position: placement.position,
        tile: <Tile grid={placement.resource.shape.grid} style={{...getResourceStyle(placement.resource.color)}} />
      }
    })

    const pieceTiles = player.board.piece_placements.map((placement) => {
      return {
        position: placement.position,
        tile: <Tile grid={placement.transformed_piece.shape.grid} style={{...getPieceStyle(placement.piece.color)}} />
      }
    })

    const possibilityTiles = currentPlayer().id === player.id && selectedPiece() ? possibilities().map((possibility) => {
      return {
        position: possibility.position,
        tile: <Tile grid={[[1]]} style={{...getPossibilityStyle(selectedPiece().color, possibility.selected)}} />
      }
    }) : []

    const tiles = roomTiles.concat(possibilityTiles).concat(pieceTiles).concat(resourcePlacements)

    return (
      <div class='flex flex-col text-neutral-content overflow-auto'>
        <div class={`text-center ${player.id === game().winner ? 'text-green-600' : player.id === currentPlayer().id && !isGameEnded() ? 'text-blue-600' : 'text-white'}`}>
          <div>
            {player.type === PlayerType.human ? 'Human' : 'AI'} ({player.score})
            <Show when={player.id === game().winner}>
              <span class='ml-1'>(Winner)</span>
            </Show>
          </div>
          <div class="text-sm">
            (Rat: {player.score_breakup.rat}, Room: {player.score_breakup.room}, Cat family: {player.score_breakup.cat_family}, Family card: {player.score_breakup.family_card})
          </div>
        </div>
        
        <div class='grow py-4 flex justify-center'>
          <Board
            grid={player.board.shape.grid}
            style={BOARD_STYLE}
            maxWidth={maxWidth()}
            tiles={tiles}
            onBoardCellSelect={(position) => onBoardCellSelect(player, position)}
          ></Board>
        </div>
      </div>
    )
  }

  /************************************ 
  * Piece selection and possibilities *
  *************************************/
  // variables
  const [selectedPiece, setSelectedPiece] = createSignal<Piece>(null);
  const [selectedPositions, setSelectedPositions] = createSignal<Position[]>([])
  const [possibilities, setPossibilities] = createSignal<Possibility[]>([])
  const [finalPlacement, setFinalPlacement] = createSignal<PiecePlacement | null>(null)

  // helpers
  const resetSelectedPiece = () => {
    setSelectedPiece(null)
    setSelectedPositions([])
    setPossibilities([])
    setFinalPlacement(null)
  }

  const triggerGetPossibilities = () => {
    const message: PossibilitiesRequest = {
      id: crypto.randomUUID(),
      type: RequestType.POSSIBILITIES,
      timestamp: Date.now(),
      data: {
        selected_piece: selectedPiece(),
        selected_positions: selectedPositions()
      }
    }
    sendMessage(message)
  }

  // events
  const onPoolPieceSelection = (piece: Piece) => {
    resetSelectedPiece()
    setSelectedPiece(piece)
    triggerGetPossibilities()
  }

  const onBoardCellSelect = (player: Player, position: Position) => {
    if (currentPlayer().id !== player.id) {
      return
    }
    if (!selectedPiece()) {
      return
    }
    if (!possibilities().find((possibility) => possibility.position.x === position.x && possibility.position.y === position.y)) {
      // chose a cell that is not among valid possibilities
      setSelectedPositions([])
    }
    else if (selectedPositions().find((pos) => pos.x === position.x && pos.y === position.y)) {
      // possibility already selected, unselect it
      setSelectedPositions(
        selectedPositions().filter((pos) => !(pos.x === position.x && pos.y === position.y))
      )
    }
    else {
      // new possibility selected
      setSelectedPositions(
        selectedPositions().concat([position])
      )
    }
    triggerGetPossibilities()
  }

  const resetTurn = () => {
    onPoolPieceSelection(selectedPiece())
  }

  /************ 
  * Make move *
  *************/
  // events
  const passMove = () => {
    const message: PassMoveRequest = {
      id: crypto.randomUUID(),
      type: RequestType.PASS_MOVE,
      timestamp: Date.now(),
      data: {}
    }
    sendMessage(message)
  }

  const makeMove = () => {
    const message: MakeMoveRequest = {
      id: crypto.randomUUID(),
      type: RequestType.MAKE_MOVE,
      timestamp: Date.now(),
      data: {
        piece_placement: finalPlacement()
      }
    }
    sendMessage(message)
  }

  /*********
  UI Tiles *
  *********/


  /*******************
  * Response handler *
  ********************/
  const handleMessage = (message: Response) => {
    const { type, data } = message
    switch (type) {
      case ResponseType.INIT, ResponseType.GAME_STATE: {
        batch(() => {
          setGame(message.data.game)
          resetSelectedPiece()
        })
        break
      }
      case ResponseType.POSSIBILITIES: {
        batch(() => {
          setPossibilities(message.data.possibilities)
          setFinalPlacement(message.data.final_placement)
        })
        break
      }
    }
  }
  
  /*******
  * Init *
  ********/
  const initialOrder = game().players.map((x) => x.id)
  props.ref({
    handleMessage: handleMessage
  })

  onMount(() => {
    resizeHandler()
  })

  /* Setup resizer */
   const resizeHandler = () => {
    if (playerContainerRef()) {
      const [playerContainerGap, playerContainerPadding] = [16, 8]
      const playerContainerWidth = playerContainerRef().getBoundingClientRect().width
      const width = (playerContainerWidth - (playerContainerGap + playerContainerPadding*2))/2
      setMaxWidth(width)
    }
    else {
      setMaxWidth(window.innerWidth/2)
    }
  };
  window.addEventListener('resize', resizeHandler);
  resizeHandler();
  onCleanup(() => {
    window.removeEventListener('resize', resizeHandler);
  });

  /*****
  * UI *
  ******/
  return (
    <div class='flex flex-col flex-1 h-full items-center gap-4 pt-8 pb-32 xl:pt-0 xl:pb-0'>
      <div class='flex flex-col'>
        <div class="flex justify-between items-center gap-4 pt-3 pb-2 px-4">
          <Show when={schema()}>
            <div class="flex-none">
              <NewGameButton schema={schema()} defaultSettings={defaultSettings()} />
            </div>
          </Show>
          <div class="flex-grow text-center text-white">
            <div>Round: {game().current_round}/{game().total_rounds} {isGameEnded() ? '(Game over)': ''}</div>
          </div>
          <div class="flex gap-4 flex-none mr-2">
            {/* <Button
              variant="outline"
              disabled={!isMyTurn() || selectedPositions().length === 0}
              onClick={resetTurn}
            >
              Reset
            </Button> */}
            <Button
              variant="outline"
              disabled={!isMyTurn() || currentPlayer().is_next_move_treasure}
              onClick={passMove}
            >
              Pass
            </Button>
            <Button
              variant="outline"
              disabled={!isMyTurn() || !finalPlacement()}
              onClick={makeMove}
            >
              Save
            </Button>
          </div>
        </div>

        {/* Player pool */}
        {poolJsx()}
      </div>


      <div ref={setPlayerContainerRef} class="flex flex-wrap gap-4 px-2 justify-center w-full">
        <div>
          {playerLayoutJsx(initialOrder[0])}
        </div>
        <div >
        {playerLayoutJsx(initialOrder[1])}
        </div>
      </div>
    </div>

  )
}