import { GameStateResponsePayloadGeneric, InitResponsePayloadGeneric, PossibilitiesResponsePayloadGeneric, ResponseGeneric, ResponseTypeGeneric } from "../baseGame/response";
import { Game, Piece } from "./objects";


export enum ResponseType {
  INIT = ResponseTypeGeneric.INIT,
  GAME_STATE = ResponseTypeGeneric.GAME_STATE,
  POSSIBILITIES = ResponseTypeGeneric.POSSIBILITIES
}

// Init
export type InitResponsePayload = InitResponsePayloadGeneric<Game>

export type InitResponse = ResponseGeneric<ResponseType.INIT, InitResponsePayload>


// Game state
export type GameStateResponsePayload = GameStateResponsePayloadGeneric<Game>

export type GameStateResponse = ResponseGeneric<ResponseType.GAME_STATE, GameStateResponsePayload>


// Possibilities
export type PossibilitiesResponsePayload = PossibilitiesResponsePayloadGeneric<Piece>

export type PossibilitiesResponse = ResponseGeneric<ResponseType.POSSIBILITIES, PossibilitiesResponsePayload>


// All requests
export type Response = InitResponse | GameStateResponse | PossibilitiesResponse