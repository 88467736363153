import axios, { AxiosResponseHeaders, RawAxiosResponseHeaders, type AxiosRequestConfig, type AxiosResponse } from 'axios';
import { HttpErrorPayload } from './http';


const formatAxiosError = <T=any>(error: any): HttpErrorPayload<T> => {
    if (!axios.isAxiosError(error)) {
        throw error
    }

    // Check if the error has a response (i.e., it came from the server)
    if (error.response) {
        // Server responded with an error status code
        return error.response.data
    } else if (error.request) {
        // The request was made but no response was received
        return {
            status: 500,
            code: error.code || 'server_error',
            message: error.message
        };
    } else {
        // Something happened in setting up the request that triggered an Error
       throw error
    }
}

    

type GetFunction = <T = any>(
    url: string,
    config?: AxiosRequestConfig
) => Promise<
    | { response: T; error?: never, headers: RawAxiosResponseHeaders | AxiosResponseHeaders }
    | { response?: never; error: HttpErrorPayload, headers?: never }
>;

type PostFunction = <T = any, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
) => Promise<
    | { response: T; error?: never, headers: RawAxiosResponseHeaders | AxiosResponseHeaders } 
    | { response?: never; error: HttpErrorPayload, headers?: never }
>;

    
export const makeGetCall: GetFunction = async <T>(
    url: string,
    config?: AxiosRequestConfig
): Promise<
    | { response: T; error?: never, headers: RawAxiosResponseHeaders | AxiosResponseHeaders }
    | { response?: never; error: HttpErrorPayload, headers?: never }
> => {
    try {
        const response: AxiosResponse<T> = await axios.get<T>(url, {withCredentials: true, ...config});  // withCredentials is needed as request from a sub domain (a.myapp.com) to another subdomain (b.myapp.com) is also considered a cross origin request
        return { response: response.data, headers: response.headers };
    } catch (error: any) {
        return { error: formatAxiosError(error) };
    }
};

export const makePostCall: PostFunction = async <T>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
): Promise<
    | { response: T; error?: never, headers: RawAxiosResponseHeaders | AxiosResponseHeaders }
    | { response?: never; error: HttpErrorPayload, headers?: never }
> => {
    try {
        const response: AxiosResponse<T> = await axios.post<T>(url, data,  {withCredentials: true, ...config});  // withCredentials is needed as request from a sub domain (a.myapp.com) to another subdomain (b.myapp.com) is also considered a cross origin request
        return { response: response.data, headers: response.headers }; // Returning only data part of the response
    } catch (error: any) {
        return { error: formatAxiosError(error) };
    }
};

