import { Component, createMemo, For, mergeProps } from 'solid-js';
import "./Tile.scss"
import { TileCell } from './TileCell';
import { TileStyle } from './types';
import { Grid, GridCell, Position } from '../Games/baseGame/objects';

/* Usage 
1. No gap
  1.1 No internal border
    <Tile 
        style={{color: "transparent", opacity: 1, gap: 0, border: {color: "black", width: 0.05}, borderRadius: 0.1, showInternalBorder: false}}
        grid={[
          [1, 1, 1],
          [0, 0, 1],
          [0, 1, 1]
        ]}
      />
  1.2 internal border
    <Tile 
        style={{color: "transparent", opacity: 1, gap: 0, border: {color: "black", width: 0.05}, borderRadius: 0.1, showInternalBorder: true}}
        grid={[
          [1, 1, 1],
          [0, 0, 1],
          [0, 1, 1]
        ]}
      />

2. No gap
  2.1 No internal border
    <Tile 
        style={{color: "transparent", opacity: 1, gap: 0.1, border: {color: "black", width: 0.05}, borderRadius: 0.1, showInternalBorder: false}}
        grid={[
          [1, 1, 1],
          [0, 0, 1],
          [0, 1, 1]
        ]}
      />
  2.2 internal border
    <Tile 
        style={{color: "transparent", opacity: 1, gap: 0.1, border: {color: "black", width: 0.05}, borderRadius: 0.1, showInternalBorder: true}}
        grid={[
          [1, 1, 1],
          [0, 0, 1],
          [0, 1, 1]
        ]}
      />
3. Single cell
 <Tile 
    style={{color: "red", opacity: 1, gap: 0, border: {color: "black", width: 0.05}, borderRadius: 0.1}}
    grid={[
      [1]
    ]}
  />
*/

export const getConnectionMatrix = (grid: Grid, position: Position, disconnected=false): Grid => {
  if (disconnected) {
    return [
      [0, 0, 0],
      [0, 0, 0],
      [0, 0, 0]
    ]
  }
  const rows = grid.length
  const cols = grid[0].length
  
  const matrix = []
  for (let i=-1; i<=1; i++) {
    let row = []
    for (let j=-1; j<=1; j++) {
      const [x, y] = [position.x + j, position.y + i]
      if (x < 0 || x >= cols || y < 0 || y >= rows) {
        row.push(null)
      }
      else {
        row.push(grid[y][x])
      }
    }
    matrix.push(row)
  }

  return matrix
}

export type TileProps = {
  grid: GridCell[][]
  style: TileStyle,
  disconnected?: boolean
  onCellSelect?: (position: Position) => void
}

export const Tile: Component<TileProps> = (_props) => {
  /* Variables */
  const props = mergeProps({}, _props)
  const grid = () => props.grid
  const style = () => props.style
  const disconnected = () => props.disconnected || false

  /* Computed variables */
  const rows = createMemo(() => grid().length)
  const cols = createMemo(() => rows() > 0 ? grid()[0].length : 0)

  return (
    <div class='relative grid justify-center content-center inset-0' style={`grid-template-columns: repeat(${cols()}, 1em); grid-template-rows: repeat(${rows()}, 1em);`}>
      <For each={grid()}>
      {(gridRow, yPos) => (
        <For each={gridRow}>
          {(value, xPos) => {
            return value === 1 ? (
              <div onClick={props.onCellSelect ? () => props.onCellSelect({x: xPos(), y: yPos()}): undefined}>
                <TileCell
                  value={value}
                  style={style()}
                connectionMatrix={getConnectionMatrix(grid(), {x: xPos(), y: yPos()}, disconnected())}
                >
                </TileCell>
              </div>
            ) : <div style="z-index: -1"></div>
          }}
        </For>
      )}
    </For>
    </div>
    
  )
}