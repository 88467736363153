import { Component, createEffect, createMemo, createSignal, For, mergeProps, onCleanup, Show, JSX } from 'solid-js';
import "./Board.scss"
import { Grid, Position } from '../Games/baseGame/objects';
import { Tile, TileProps } from '../Tile/Tile';
import { TileStyle } from '../Tile/types';
import { isNullOrUndefined } from '@polyomino/ts-utils/src/lib/utils';

export type BoardProps = {
  grid: Grid;
  style: TileStyle
  maxWidth?: number;
  maxHeight?: number;
  minCellSize?: number;
  showBorder?: boolean;
  tiles: {position: Position, tile: JSX.Element}[]
  onBoardCellSelect?: (position: Position) => void
}

const computeTileSize = (rows: number, cols: number, maxContainerWidth?: number, maxContainerHeight?: number, minCellSize?: number) => {
  const gap = (16 + 4)*2  // padding + border in this component
  const otherOffset = 15  // scrollbar width (if it comes)
  if (isNullOrUndefined(maxContainerWidth)) {
    maxContainerWidth = window.innerWidth
  }
  if (isNullOrUndefined(maxContainerHeight)) {
    maxContainerHeight = window.innerHeight
  }
  if (isNullOrUndefined(minCellSize)) {
    minCellSize = 25
  }
  [maxContainerWidth, maxContainerHeight] = [maxContainerWidth-gap-otherOffset, maxContainerHeight-gap-otherOffset]
  const maxCellWidth = maxContainerWidth / cols
  const maxCellHeight = maxContainerHeight / rows
  const maxCellSize = Math.min(maxCellWidth, maxCellHeight)
  
  let newSize =
    window.innerWidth < 1280
      ? Math.min(
        (window.innerWidth - 120) / cols,
        (window.innerHeight - 180) / rows
      )
      : Math.min(
        (window.innerWidth - 120 - 640) / cols,
        (window.innerHeight - 180) / rows
      );
  newSize = Math.max(Math.min(newSize, maxCellSize), minCellSize)

  return newSize
}

export const Board: Component<BoardProps> = (_props) => {
  /* Variables */
  const props = mergeProps({showBorder: true}, _props)
  const grid = () => props.grid
  const style = () => props.style
  const maxWidth = () => props.maxWidth
  const maxHeight = () => props.maxHeight
  const minCellSize = () => props.minCellSize
  const tiles = () => props.tiles

  const [unitSize, setUnitSize] = createSignal(0)

  /* Computed variables */
  const rows = () => props.grid.length
  const cols = () => props.grid[0].length

  // Init
  createEffect(() => {
    // Recompute unitSize
    setUnitSize(computeTileSize(rows(), cols(), maxWidth(), maxHeight(), minCellSize()))

    console.log("createEffect -> setUnitSize")
  })

  /* Setup resizer */
  const resizeHandler = () => {
    setUnitSize(computeTileSize(rows(), cols(), maxWidth(), maxHeight(), minCellSize()))
  };
  window.addEventListener('resize', resizeHandler);
  resizeHandler();
  onCleanup(() => {
    window.removeEventListener('resize', resizeHandler);
  });

  return (
    <div class={`w-fit h-fit ${props.showBorder ? 'grid-border border-4 p-4 rounded-xl border-primary/10' : ''}`}>

      <div class='relative' style={`width: ${unitSize() * cols()}px; height: ${unitSize() * rows()}px; min-width: ${unitSize() * cols()}px; min-height: ${unitSize() * rows()}px; font-size: ${unitSize()}px`}>
        <div class='grid justify-center content-center absolute inset-0' style={`grid-template-columns: repeat(${cols()}, ${unitSize()}px); grid-template-rows: repeat(${rows()}, ${unitSize()}px);`}>
          {/* Board cells */}
          <div class='absolute' style={`top: 0em; left: 0em`}>
            <Tile
              grid={grid()}
              style={style()}
              disconnected={true}
            >
            </Tile>
          </div>

          {/* Tiles */}
          <div>
            <For each={tiles()}>
              {(tilePlacement, index) => {
                return (
                  <div class='absolute' style={`top: ${tilePlacement.position.y}em; left: ${tilePlacement.position.x}em`}>
                    {tilePlacement.tile}
                  </div>
                )
              }}
            </For>
          </div>

          {/* Dummy board cells to handle click events */}
          <div class='absolute' style={`top: 0em; left: 0em`}>
            <Tile
              grid={grid()}
              style={{color: "transparent"}}
              disconnected={true}
              onCellSelect={props.onBoardCellSelect}
            >
            </Tile>
          </div>

        </div>
      </div>
    </div>
  )
}