import { GameGeneric, GameType, PlayerGeneric } from "@/components/Games/baseGame/objects"
import { BACKEND_HTTP_BASE_URL } from "@/config"
import { makeGetCall, makePostCall } from "@polyomino/ts-utils/src/lib/axios-utils"


export type GetSchemaServiceResponse = {
  schema: any, default_settings: {games: any, default: GameType}
}
export const getSchemaService = async (abortController?: AbortController) => {
  let url = `${BACKEND_HTTP_BASE_URL}/game/schema`
  return makeGetCall<GetSchemaServiceResponse>(url, abortController ? {signal: abortController.signal}: undefined)
}


export type NewGameServiceRequest = {
  settings?: any
}
export type NewGameServiceResponse = {
  game: GameGeneric<PlayerGeneric>
}
export const newGameService = async (data: NewGameServiceRequest, abortController?: AbortController) => {
  let url = `${BACKEND_HTTP_BASE_URL}/game/create`
  return makePostCall<NewGameServiceResponse>(url, data, abortController ? {signal: abortController.signal}: undefined)
}
