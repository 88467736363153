/* Utils */
export enum GameType {
  polyssimo = "polyssimo",
  isle_of_cats = "isle_of_cats",
  barenpark = "barenpark"
}

export enum PlayerType {
  human = "human",
  ai = "ai",
}

export enum AiStrategy {
  random = "random",
  largest = "largest",
  greedy = "greedy"
}

export enum Orientation {
  h = "h",
  v = "v",
}

export enum Rotation {
  zero = 0,
  ninety = 90,
  one_eighty = 180,
  two_seventy = 270
}

export type Position = {
  x: number;
  y: number;
}


export type Possibility = {
  position: Position;
  selected: boolean;
}

export type GridCell = 0 | 1

export type Grid = GridCell[][]

export type Shape = {
  name?: string;
  grid: Grid
}

/* Generic */
export type PieceGeneric = {
  id: string
  shape: Shape
}

export type ResourceGeneric = {
  id: string
  shape: Shape
}

export type PiecePlacementGeneric<P extends PieceGeneric> = {
  position: Position;
  flip: Orientation;
  rotate: Rotation;
  piece: P
  transformed_piece: P,
}

export type BoardPiecePlacementGeneric<P extends PieceGeneric> = PiecePlacementGeneric<P> & {
  owned_by: string
}

export type ResourcePlacementGeneric<R extends ResourceGeneric> = {
  position: Position;
  resource: R
}

export type BoardResourcePlacementGeneric<R extends ResourceGeneric> = ResourcePlacementGeneric<R>

export type PlayerBaseGeneric = {
  id: string;
  type: PlayerType;
  color: string;
  score: number;
  disabled_pieces: string[]
}

export type HumanPlayerGeneric = PlayerBaseGeneric & {
  type: PlayerType.human;
  user_id: string;
}

export type AiPlayerGeneric = PlayerBaseGeneric & {
  type: PlayerType.ai;
  strategy: AiStrategy
}

export type PlayerGeneric = HumanPlayerGeneric | AiPlayerGeneric

export type PoolGeneric<P extends PieceGeneric> = {
  pieces: P[]
}

export type BoardGeneric<BoardPiecePlacementGeneric, BoardResourcePlacementGeneric> = {
  id: string;
  rows: number;
  cols: number;
  shape: Shape;
  piece_placements: BoardPiecePlacementGeneric[]
  resource_placements: BoardResourcePlacementGeneric[]
}

export type GameGeneric<PL extends PlayerGeneric> = {
  id: string;
  type: GameType;
  players: PL[];
  current_turn: string;
  ended: boolean;
  winner?: string
}
