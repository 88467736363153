import { MakeMoveRequestPayloadGeneric, PosibilitiesRequestPayloadGeneric, RequestGeneric, RequestTypeGeneric } from "../baseGame/request";
import { Piece } from "./objects";

export enum RequestType {
  POSSIBILITIES = RequestTypeGeneric.POSSIBILITIES,
  MAKE_MOVE = RequestTypeGeneric.MAKE_MOVE,
  PASS_MOVE = RequestTypeGeneric.PASS_MOVE,
}

// Possibilities
export type PossibilitiesRequestPayload = PosibilitiesRequestPayloadGeneric<Piece>

export type PossibilitiesRequest = RequestGeneric<RequestType.POSSIBILITIES, PossibilitiesRequestPayload>


// Make move
export type MakeMoveRequestPayload = MakeMoveRequestPayloadGeneric<Piece>

export type MakeMoveRequest = RequestGeneric<RequestType.MAKE_MOVE, MakeMoveRequestPayload>


// Make move
export type PassMoveRequestPayload = {}

export type PassMoveRequest = RequestGeneric<RequestType.PASS_MOVE, PassMoveRequestPayload>


// All requests
export type Request = PossibilitiesRequest | MakeMoveRequest | PassMoveRequest