import { Component, createEffect, createSignal, For, JSX, mergeProps, onCleanup, onMount } from 'solid-js';
import "./Pool.scss";
import { PieceGeneric, PoolGeneric } from '../Games/baseGame/objects';

export type PoolProps<Pool extends PoolGeneric<PieceGeneric & {tile: JSX.Element, heading?: JSX.Element}>> = {
  disabled: boolean;
  orientation: "horizontal" | "vertical";
  pool: Pool;
  disabledPieces: string[];
  selectedPiece?: PieceGeneric;
  onPieceSelect?: (piece: PieceGeneric) => void;
  class?: string
} 

export const Pool = <Pool extends PoolGeneric<PieceGeneric & {tile: JSX.Element, heading?: JSX.Element}>>(_props: PoolProps<Pool>) => {
  const unitSize = 18;
  const props = mergeProps({disabled: false}, _props);
  const pieces = () => props.pool.pieces
  const disabledPieces = () => props.disabledPieces
  const orientation = () => props.orientation

  return (
    <div id="pool-container" class={`rounded-2xl bg-gray-800/90 backdrop-blur-sm shadow-lg flex p-4 gap-3 flex-wrap max-h-full max-w-full ${props.class || ''} ${orientation() === 'vertical' ? 'flex-col overflow-x-auto' : 'justify-center overflow-y-auto h-fit'}`}>
      <For each={pieces()}>
        {(piece) => (
          <div class='flex flex-col gap-y-1'>
            <div class='text-white text-center'>{piece.heading}</div>
            <div 
              onClick={() => (props.disabled || disabledPieces().includes(piece.id)) ? null : props.onPieceSelect?.(piece)}
              class={`piece-holder p-1 flex items-center justify-center rounded-xl ${props.disabled ? 'disabled' : disabledPieces().includes(piece.id) ? 'disabled cursor-not-allowed' : ''} ${props.selectedPiece?.id === piece.id? 'selected' : ''}`}
              style={`min-height: ${4*unitSize + 4}px; min-width: ${4*unitSize + 4}px;`}
            >
              {piece.tile}
            </div>
          </div>
          
        )}
      </For>
    </div> 
  );
};
