import { Component, createEffect, createMemo, For, JSX, mergeProps, Show } from "solid-js";
import { Grid, GridCell } from "../Games/baseGame/objects";
import { isNullOrUndefined } from "@polyomino/ts-utils/src/lib/utils";
import { TileStyle } from "./types";
import { CellStyle } from "./cell.types";

export const borderToBoxShadowCss = (border: CellStyle["border"]): {"box-shadow": string} => {    
  const {
    top, right, bottom, left
  } = (border || {})

  // Determine border width and color
  const _c = (color?: string) => color || 'black'
  const boxShadowArr = [
    // top
    (isNullOrUndefined(top?.width) ? '' : `0 -${top.width/2}em 0 0 ${_c(top.color)}, inset 0 ${top.width/2}em 0 0 ${_c(top.color)}`),
    // right
    (isNullOrUndefined(right?.width) ? '' : `${right.width/2}em 0 0 0 ${_c(right.color)}, inset -${right.width/2}em 0 0 0 ${_c(right.color)}`),
    // bottom
    (isNullOrUndefined(bottom?.width) ? '' : `0 ${bottom.width/2}em 0 0 ${_c(bottom.color)}, inset 0 -${bottom.width/2}em 0 0 ${_c(bottom.color)}`),
    // left
    (isNullOrUndefined(left?.width) ? '' : `-${left.width/2}em 0 0 0 ${_c(left?.color)}, inset ${left.width/2}em 0 0 0 ${_c(left.color)}`),
  ]
  // const boxShadowArr = [
  //   // top
  //   (isNullOrUndefined(top?.width) ? '' : `inset 0 ${top.width}em 0 0 ${_c(top.color)}`),
  //   // right
  //   (isNullOrUndefined(right?.width) ? '' : `inset -${right.width}em 0 0 0 ${_c(right.color)}`),
  //   // bottom
  //   (isNullOrUndefined(bottom?.width) ? '' : `inset 0 -${bottom.width}em 0 0 ${_c(bottom.color)}`),
  //   // left
  //   (isNullOrUndefined(left?.width) ? '' : `inset ${left.width}em 0 0 0 ${_c(left.color)}`),
  // ]

  const boxShadowCss = boxShadowArr.filter((x) => !!x.trim()).join(', ')

  return {
    "box-shadow": boxShadowCss,
  }
}


export type ConnectionMatrix = Grid  // null if out of bounds

export type TileCellProps = {
  value: GridCell,
  style: TileStyle;
  connectionMatrix: ConnectionMatrix
}

type TileCellBreakupStyle = {
  top: number; right: number; bottom: number; left: number;
  width: number; height: number;
  color: string; opacity: number;
  borderColor: string,
  borderTopWidth: number, borderRightWidth: number, borderBottomWidth: number, borderLeftWidth: number,
  borderTopLeftRadius: number; borderTopRightRadius: number; borderBottomLeftRadius: number; borderBottomRightRadius: number
}

const getTileBreakup = (style: TileStyle, connectionMatrix: ConnectionMatrix): TileCellBreakupStyle[] => {
  // Constants
  const [showInternalBorder, gap, color, opacity] = [
    !!style.showInternalBorder, style.gap || 0, style.color, isNullOrUndefined(style.opacity) ? 1 : style.opacity
  ]
  let [borderColor, borderWidth, borderRadius] = [
    style.border?.color || '', style.border?.width || 0, style.borderRadius || 0
  ]

  if (gap == 0) {
    const c = connectionMatrix
    if (showInternalBorder) {
      return [{
        top: 0, bottom: 0, left: 0, right: 0,
        width: 1, height: 1,
        color, opacity,
        borderColor,
  
        borderTopWidth: borderWidth, 
        borderRightWidth: borderWidth, 
        borderBottomWidth: borderWidth, 
        borderLeftWidth: borderWidth,
  
        borderTopLeftRadius: c[0][0] || c[0][1] || c[1][0] ? 0 : borderRadius, 
        borderTopRightRadius: c[0][1] || c[0][2] || c[1][2] ? 0 : borderRadius, 
        borderBottomRightRadius: c[1][2] || c[2][1] || c[2][2] ? 0: borderRadius,
        borderBottomLeftRadius: c[1][0] || c[2][0] || c[2][1] ? 0: borderRadius,
      }]
    }
    else {
      return [{
        top: 0, bottom: 0, left: 0, right: 0,
        width: 1, height: 1,
        color, opacity,
        borderColor,
  
        borderTopWidth:  c[0][1] ? 0 : borderWidth, 
        borderRightWidth: c[1][2] ? 0 : borderWidth, 
        borderBottomWidth: c[2][1] ? 0 : borderWidth, 
        borderLeftWidth: c[1][0] ? 0 : borderWidth, 
  
        borderTopLeftRadius: c[0][0] || c[0][1] || c[1][0] ? 0 : borderRadius, 
        borderTopRightRadius: c[0][1] || c[0][2] || c[1][2] ? 0 : borderRadius, 
        borderBottomRightRadius: c[1][2] || c[2][1] || c[2][2] ? 0: borderRadius,
        borderBottomLeftRadius: c[1][0] || c[2][0] || c[2][1] ? 0: borderRadius,
      }]
    }
    
  }

  let centerTile: TileCellBreakupStyle = {
    top: gap, bottom: gap, left: gap, right: gap,
    width: 1 - 2 * gap, height: 1 - 2 * gap,
    color, opacity,
    borderColor,
    borderTopWidth: borderWidth, 
    borderRightWidth: borderWidth, 
    borderBottomWidth: borderWidth, 
    borderLeftWidth: borderWidth,
    borderTopLeftRadius: borderRadius, 
    borderTopRightRadius: borderRadius, 
    borderBottomLeftRadius: borderRadius, 
    borderBottomRightRadius: borderRadius
  }

  const matrix: (TileCellBreakupStyle | null)[][] = [
    [null, null, null],
    [null, centerTile, null],
    [null, null, null]
  ]
  
  // Fill up top/right/bottom/left tiles
  const permutations = [
    {orientation: "top", pos: [1, 0], 
      centerTileUpdate: {borderTopLeftRadius: 0, borderTopRightRadius: 0, borderTopWidth: 0}, 
      tile: {
        top: 0, bottom: 1 - gap, left: gap, right: gap,
        width: 1 - 2*gap, height: gap,
        color, opacity,
        borderTopWidth: borderWidth, borderRightWidth: borderWidth, borderBottomWidth: 0, borderLeftWidth: borderWidth,
        borderColor,
        borderTopLeftRadius: 0, borderTopRightRadius: 0, borderBottomLeftRadius: 0, borderBottomRightRadius: 0
      }
    },
    {orientation: "right", pos: [2, 1], 
      centerTileUpdate: {borderTopRightRadius: 0, borderBottomRightRadius: 0, borderRightWidth: 0}, 
      tile: {
        top: gap, bottom: gap, left: 1 - gap, right: 0,
        width: gap, height: 1- 2 * gap,
        color, opacity,
        borderColor,
        borderTopWidth: borderWidth, borderRightWidth: borderWidth, borderBottomWidth: borderWidth, borderLeftWidth: 0,
        borderTopLeftRadius: 0, borderTopRightRadius: 0, borderBottomLeftRadius: 0, borderBottomRightRadius: 0
      }
    },
    {orientation: "bottom", pos: [1, 2], 
      centerTileUpdate: {borderBottomRightRadius: 0, borderBottomLeftRadius: 0, borderBottomWidth: 0}, 
      tile: {
        top: 1 - gap, bottom: 0, left: gap, right: gap,
        width: 1 - 2*gap, height: gap,
        color, opacity,
        borderColor,
        borderTopWidth: 0, borderRightWidth: borderWidth, borderBottomWidth: borderWidth, borderLeftWidth: borderWidth,
        borderTopLeftRadius: 0, borderTopRightRadius: 0, borderBottomLeftRadius: 0, borderBottomRightRadius: 0
      }
    },
    {orientation: "left", pos: [0, 1], 
      centerTileUpdate: {borderBottomLeftRadius: 0, borderTopLeftRadius: 0, borderLeftWidth: 0}, 
      tile: {
        top: gap, bottom: gap, left: 0, right: 1 - gap,
        width: gap, height: 1 - 2*gap,
        color, opacity,
        borderColor,
        borderTopWidth: borderWidth, borderRightWidth: 0, borderBottomWidth: borderWidth, borderLeftWidth: borderWidth,
        borderTopLeftRadius: 0, borderTopRightRadius: 0, borderBottomLeftRadius: 0, borderBottomRightRadius: 0
      }
    },
  ]
  for (const {orientation, pos, centerTileUpdate, tile} of permutations) {
    const [x, y] = pos

    if (isNullOrUndefined(connectionMatrix[y][x]) || connectionMatrix[y][x] === 0) {
      continue
    }

    matrix[y][x] = tile

    // Make updates to central tile
    matrix[1][1] = {
      ...matrix[1][1],
      ...centerTileUpdate
    }
  }

  // Fill up corner tiles
  const cornerPermutations = [
    {orientation: "top-left", pos: [0, 0], 
      neighbours: [{pos: [1, 0], updates: {borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderLeftWidth: 0}}, {pos: [0, 1], updates: {borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderTopWidth: 0}}], 
      tile: {
        top: 0, bottom: 1 - gap, left: 0, right: 1 - gap,
        width: gap, height: gap,
        color, opacity,
        borderColor,
        borderTopWidth: borderWidth, borderRightWidth: 0, borderBottomWidth: 0, borderLeftWidth: borderWidth,
        borderTopLeftRadius: 0, borderTopRightRadius: 0, borderBottomLeftRadius: 0, borderBottomRightRadius: 0
      }
    },
    {orientation: "top-right", pos: [2, 0], 
      neighbours: [{pos: [1, 0], updates: {borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderRightWidth: 0}}, {pos: [2, 1], updates: {borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderTopWidth: 0}}], 
      tile: {
        top: 0, bottom: 1 - gap, left: 1 - gap, right: 0,
        width: gap, height: gap,
        color, opacity,
        borderColor,
        borderTopWidth: borderWidth, borderRightWidth: borderWidth, borderBottomWidth: 0, borderLeftWidth: 0,
        borderTopLeftRadius: 0, borderTopRightRadius: 0, borderBottomLeftRadius: 0, borderBottomRightRadius: 0
      }
    },
    {orientation: "bottom-right", pos: [2, 2], 
      neighbours: [{pos: [2, 1], updates: {borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderBottomWidth: 0}}, {pos: [1, 2], updates: {borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderRightWidth: 0}}], 
      tile: {
        top: 1 - gap, bottom: 0, left: 1 - gap, right: 0,
        width: gap, height: gap,
        color, opacity,
        borderColor,
        borderTopWidth: 0, borderRightWidth: borderWidth, borderBottomWidth: borderWidth, borderLeftWidth: 0,
        borderTopLeftRadius: 0, borderTopRightRadius: 0, borderBottomLeftRadius: 0, borderBottomRightRadius: 0
      }
    },
    {orientation: "bottom-left", pos: [0, 2], 
      neighbours: [{pos: [1, 2], updates: {borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderLeftWidth: 0}}, {pos: [0, 1], updates: {borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderBottomWidth: 0}}], 
      tile: {
        top: 1 - gap, bottom: 0, left: 0, right: 1- gap,
        width: gap, height: gap,
        color, opacity,
        borderColor,
        borderTopWidth: 0, borderRightWidth: 0, borderBottomWidth: borderWidth, borderLeftWidth: borderWidth,
        borderTopLeftRadius: 0, borderTopRightRadius: 0, borderBottomLeftRadius: 0, borderBottomRightRadius: 0
      }
    }
  ]
  for (const {orientation, pos, neighbours, tile} of cornerPermutations) {
    const [x, y] = pos

    if (isNullOrUndefined(connectionMatrix[y][x]) || connectionMatrix[y][x] === 0){
      continue
    }

    let connected = true
    for (let neighbour of neighbours) {
      if (!matrix[neighbour.pos[1]][neighbour.pos[0]]) {
        connected = false
        break
      }
    }
    if (!connected) {
      continue
    }

    matrix[y][x] = tile

    // Make updates to neighbours properties
    for (let neighbour of neighbours) {
      const pos = neighbour.pos
      matrix[pos[1]][pos[0]] = {
        ...matrix[pos[1]][pos[0]],
        ...neighbour.updates
      }
    }
  }

  // Right now interal borders are visible
  if (!showInternalBorder) {
    const c = connectionMatrix
    for (let j=0; j<matrix.length; j++) {
      for (let i=0; i<matrix[j].length; i++) {
        if (!matrix[j][i]) {
          continue
        }
        // Remove top border
        if (c[0][1]) {
          matrix[j][i].borderTopWidth = 0
        }
        // Remove right border
        if (c[1][2]) {
          matrix[j][i].borderRightWidth = 0
        }
        // Remove bottom border
        if (c[2][1]) {
          matrix[j][i].borderBottomWidth = 0
        }
        // Remove left border
        if (c[1][0]) {
          matrix[j][i].borderLeftWidth = 0
        }

        // Remove border radius
        if (c[0][0] || c[0][1] || c[1][0]) {
          matrix[j][i].borderTopLeftRadius = 0
        }

        if (c[0][1] || c[0][2] || c[1][2]) {
          matrix[j][i].borderTopRightRadius = 0

        }

        if (c[1][2] || c[2][1] || c[2][2]) {
          matrix[j][i].borderBottomRightRadius = 0
        }

        if (c[1][0] || c[2][0] || c[2][1]) {
          matrix[j][i].borderBottomLeftRadius = 0
        }
      }
    }

  }

  // TODO: Optimise matrix

  // Return tiles
  const tiles = matrix.flat().flat().filter((x) => !!x)
  return tiles
}

export const TileCell: Component<TileCellProps> = (_props) => {
  const props = mergeProps(_props);
 
  /* Computed variables */ 
  const exists = createMemo(() => props.value === 1)

  const cellStyles = createMemo(() => {
    return props.value === 1 ? getTileBreakup(props.style, props.connectionMatrix) : []
  })
  
  return (
    <div class='relative' style={`width: 1em; height: 1em; transform: scale(1);`}>
      <Show when={exists()}>
        <For each={cellStyles()}>
          {(style) => (
            <div
              class="absolute p-0 shadow-none min-h-0 border-0"
              style={`
                top: ${style.top}em; right: ${style.right}em; bottom: ${style.bottom}em; left: ${style.left}em;
                width: ${style.width}em; height: ${style.height}em; 
                box-shadow: ${borderToBoxShadowCss({
                  top: {color: style.borderColor, width: style.borderTopWidth},
                  right: {color: style.borderColor, width: style.borderRightWidth},
                  bottom: {color: style.borderColor, width: style.borderBottomWidth},
                  left: {color: style.borderColor, width: style.borderLeftWidth},
                })['box-shadow']};
                border-top-left-radius: ${style.borderTopLeftRadius}em; 
                border-top-right-radius: ${style.borderTopRightRadius}em; 
                border-bottom-left-radius: ${style.borderBottomLeftRadius}em; 
                border-bottom-right-radius: ${style.borderBottomRightRadius}em;
                background-color: ${style.color};
                opacity: ${style.opacity};
              `}
            >
            </div>
          )}
        </For>
      </Show>
    </div>
  )
}