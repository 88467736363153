import { MakeMoveRequestPayloadGeneric, PosibilitiesRequestPayloadGeneric, RequestGeneric, RequestTypeGeneric } from "../baseGame/request";
import { Board, ExtensionPlacement, Piece } from "./objects";

export enum RequestType {
  POSSIBILITIES = RequestTypeGeneric.POSSIBILITIES,
  MAKE_MOVE = RequestTypeGeneric.MAKE_MOVE,
  PASS_MOVE = RequestTypeGeneric.PASS_MOVE,
  EXTEND_GRID = "EXTEND_GRID"
}

// Possibilities
export type PossibilitiesRequestPayload = PosibilitiesRequestPayloadGeneric<Piece>

export type PossibilitiesRequest = RequestGeneric<RequestType.POSSIBILITIES, PossibilitiesRequestPayload>


// Make move
export type MakeMoveRequestPayload = MakeMoveRequestPayloadGeneric<Piece>

export type MakeMoveRequest = RequestGeneric<RequestType.MAKE_MOVE, MakeMoveRequestPayload>


// Make move
export type PassMoveRequestPayload = {
  piece: Piece
}

export type PassMoveRequest = RequestGeneric<RequestType.PASS_MOVE, PassMoveRequestPayload>


// Extend grid
export type ExtendGridRequestPayload = {
  extended_board: Board;
  placement: ExtensionPlacement
}

export type ExtendGridRequest = RequestGeneric<RequestType.EXTEND_GRID, ExtendGridRequestPayload>


// All requests
export type Request = PossibilitiesRequest | MakeMoveRequest | PassMoveRequest | ExtendGridRequest