import { MakeMoveRequestPayloadGeneric, PosibilitiesRequestPayloadGeneric, RequestGeneric, RequestTypeGeneric } from "../baseGame/request";
import { Piece } from "./objects";


export enum RequestType {
  POSSIBILITIES = RequestTypeGeneric.POSSIBILITIES,
  MAKE_MOVE = RequestTypeGeneric.MAKE_MOVE
}

// Possibilities
export type PossibilitiesRequestPayload = PosibilitiesRequestPayloadGeneric<Piece>

export type PossibilitiesRequest = RequestGeneric<RequestType.POSSIBILITIES, PossibilitiesRequestPayload>


// Make move
export type MakeMoveRequestPayload = MakeMoveRequestPayloadGeneric<Piece>

export type MakeMoveRequest = RequestGeneric<RequestType.MAKE_MOVE, MakeMoveRequestPayload>


// All requests
export type Request = PossibilitiesRequest | MakeMoveRequest