import { AiPlayerGeneric, BoardGeneric, BoardPiecePlacementGeneric, GameGeneric, GameType, HumanPlayerGeneric, PieceGeneric, PiecePlacementGeneric, PoolGeneric, Position, ResourceGeneric, ResourcePlacementGeneric, Shape } from "../baseGame/objects";

export enum ResourceType {
    wheelbarrow = "wheelbarrow",
    cement_truck = "cement_truck",
    excavator = "excavator",
    construction = "construction",
    pit = "pit"
}

export type Piece = PieceGeneric & {
  color: string;
  score?: number;
  resource: ResourceType
}

export type PiecePlacement = PiecePlacementGeneric<Piece>

export type BoardPiecePlacement = BoardPiecePlacementGeneric<Piece>

export type Resource = ResourceGeneric & {
  type: ResourceType;
  color: string;
}

export type ResourcePlacement = ResourcePlacementGeneric<Resource>

export type BoardResourcePlacement = ResourcePlacement

export type Board = BoardGeneric<BoardPiecePlacement, ResourcePlacement> & {
  name: string;
}

export type Park = {
  position: Position;
  board: Board
  is_starter: boolean
}

export type PoolBoard = Board & {pile_id:number}

export type Pool = PoolGeneric<Piece> & {
    boards: PoolBoard[]
}

export type ExtensionPlacement = {
  id: string;
  possibility_position: Position
  possibility_shape: Shape
  possibility_positions: Position[]
  position: Position
  board_id: string
}

  
export type PlayerBase = {
  board: Board;
  parks: Park[]
  allowed_extension_placements: ExtensionPlacement[]
  starting_piece: Piece;
  disabled_boards: string[];
  passed: boolean;
  passed_piece?: Piece
  next_move_add_grid: boolean;
  score_breakup: number[];
  has_pieces: number[]
}

export type AiPlayer = AiPlayerGeneric & PlayerBase

export type HumanPlayer = HumanPlayerGeneric & PlayerBase

export type Player = HumanPlayer | AiPlayer

export type Game = GameGeneric<Player> & {
  type: GameType.barenpark,
  pool: Pool
}
