
import { batch, Component, createEffect, createMemo, createSignal, Match, on, onCleanup, Show, Switch } from 'solid-js';
import {
  AlertDialog,
  AlertDialogClose,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogAction
} from "@/components/ui/alert-dialog";

import './Game.scss';
import { formUrl, sleep } from '@polyomino/ts-utils/src/lib/utils';
import { ApiLoadInfo, ApiState, SocketLoadInfo, SocketState } from "@polyomino/ts-utils/src/lib/types"
import PageLoader from '@/components/PageLoader/PageLoader';
import { Reconnecting } from './Reconnecting';
import { toaster } from "@kobalte/core";
import {
  Toast,
  ToastContent,
  ToastDescription,
  ToastProgress,
  ToastTitle
} from "@/components/ui/toast";
import { useParams, useNavigate, reload } from "@solidjs/router";
import { getSchemaService, newGameService } from "@/service/service";
import { BACKEND_SOCKET_BASE_URL } from '@/config';
import { httpErrorCodeToWsErrorCode, SocketErrorPayload } from '@polyomino/ts-utils/src/lib/http';
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger
} from "@/components/ui/collapsible";
import { GameGeneric, GameType, PlayerGeneric } from '@/components/Games/baseGame/objects';
import { InitResponsePayloadGeneric, ResponseGeneric, ResponseTypeGeneric } from '@/components/Games/baseGame/response';
import { Polyssimo } from '@/components/Games/polyssimo/Polyssimo';
import { X as XIcon } from 'lucide-solid';
import { IsleOfCats } from '@/components/Games/isleOfCats/IsleOfCats';
import { RequestGeneric } from '@/components/Games/baseGame/request';
import { Barenpark } from '@/components/Games/barenpark/Barenpark';

type PathParams = { gameId: string }


export const Game: Component = () => {
  /* Variables */
  const params = useParams<PathParams>()
  const navigate = useNavigate();

  const [schemaLoadInfo, setSchemaLoadInfo] = createSignal<ApiLoadInfo>({ state: ApiState.NOT_LOADED })
  const [schema, setSchema] = createSignal<string>(null)
  const [defaultSettings, setDefaultSettings] = createSignal<any>(null)


  const [gameId, setGameId] = createSignal<string>()
  const [pageLoadInfo, setPageLoadInfo] = createSignal<ApiLoadInfo>({ state: ApiState.NOT_LOADED })
  const [socketLoadInfo, setSocketLoadInfo] = createSignal<SocketLoadInfo>({ state: SocketState.IDLE })
  const [socket, setSocket] = createSignal<WebSocket>()
  const [hideSocketErrorModal, setHideSocketErrorModal] = createSignal<boolean>(false)
  const [resetGameView, setResetGameView] = createSignal<boolean>(true)

  const [game, setGame] = createSignal<GameGeneric<PlayerGeneric>>()
  let gameRef: any

  /* Computed Variables */
  const urlGameId = createMemo(() => params.gameId)

  /* Helpers */
  const getSocketServerUrl = (gameId: string) => {
    return formUrl({ basePath: BACKEND_SOCKET_BASE_URL, otherPath: `/game/${gameId}`, params: {} })
  }

  const loadSchema = async () => {
    setSchemaLoadInfo({ state: ApiState.LOADING })
    const { response, error } = await getSchemaService()
    if (error) {
      setSchemaLoadInfo({ state: ApiState.ERROR, error })
      return
    }
    setSchemaLoadInfo({ state: ApiState.LOADED })
    setSchema(response.schema)
    setDefaultSettings(response.default_settings)
  }

  // Socket helpers
  const setupSocket = async () => {
    closeSocket()

    // Reload state for the game component
    setResetGameView(false)
    setTimeout(() => setResetGameView(true))

    setSocketLoadInfo({ state: SocketState.LOADING })
    const _socket = new WebSocket(getSocketServerUrl(gameId()))
    setSocket(_socket)

    // Add listeners
    _socket.addEventListener("open", onSocketOpen)
    _socket.addEventListener("message", onSocketMessage)
    _socket.addEventListener("error", onSocketError)
    _socket.addEventListener("close", onSocketClose)
  }

  const closeSocket = () => {
    if (!socket()) return

    socket().removeEventListener("open", onSocketOpen)
    socket().removeEventListener("message", onSocketMessage)
    socket().removeEventListener("error", onSocketError)
    socket().removeEventListener("close", onSocketClose)

    if (socket().readyState === WebSocket.OPEN) {
      socket().close()
    }
  }

  const onSocketOpen = (event: Event) => {
    console.log('Connected to WebSocket server', event);
  }

  const onSocketMessage = (event: any) => {
    const message = JSON.parse(event.data)
    handleIncomingMessage(message)
  }

  const onSocketError = (error: Event) => {
    console.error('WebSocket error', error);
  }

  const onSocketClose = async (event: CloseEvent) => {
    console.log('Disconnected from WebSocket server', event);
    if (socketLoadInfo().error) {
      // error message already sent by server as a ws message
      return
    }

    if (socketLoadInfo().state !== SocketState.CLOSE) {
      // Socket not properly closed by server
      batch(() => {
        const error: SocketErrorPayload = {
          status: 500,
          code: "server_error",
          message: "Internal server error",
          ws_status: httpErrorCodeToWsErrorCode(500)
        }
        setHideSocketErrorModal(false)
        setSocketLoadInfo({ state: SocketState.CLOSE, error: error })
        if (pageLoadInfo().state === ApiState.LOADING) {
          setPageLoadInfo({ state: ApiState.ERROR, error: error })
        }
      })
    }
  }

  const sendMessage = (message: RequestGeneric<any, any>) => {
    if (socket() && socket().readyState === socket().OPEN) {
      console.log('request', message)
      socket().send(JSON.stringify(message))
    }
  }

  const handleIncomingMessage = (message: ResponseGeneric<any, any>) => {
    console.log('response', message)
    switch (message.type) {
      case "CLOSED": {
        const isError = !!message.data.error
        batch(() => {
          setHideSocketErrorModal(!isError)
          setSocketLoadInfo({ state: SocketState.CLOSE, error: message.data.error })
          if (isError && pageLoadInfo().state === ApiState.LOADING) {
            setPageLoadInfo({ state: ApiState.ERROR, error: message.data.error })
          }
        })
        break
      }
      default: {
        if (message.type === ResponseTypeGeneric.INIT) {
          batch(() => {
            setSocketLoadInfo({ state: SocketState.OPEN })
            if (pageLoadInfo().state === ApiState.LOADING) {
              setPageLoadInfo({ state: ApiState.LOADED })
            }
            setGame((message.data as InitResponsePayloadGeneric<GameGeneric<PlayerGeneric>>).game)
          })
        }
        gameRef?.handleMessage(message)
      }
    }
  }

  /* UI events */
  const reconnect = async () => {
    setSocketLoadInfo({ state: SocketState.LOADING })
    await sleep(1000)
    setupSocket()
  }

  /* Lifecycle */
  const init = async () => {
    await loadSchema()
  }

  // When url gameId changes
  createEffect(on(urlGameId, async (urlGameId) => {
    if (gameId() && params.gameId === gameId()) {
      return
    }
    setGameId(urlGameId)

    console.log("url gameId changed", urlGameId)

    // Reset all variables
    setPageLoadInfo({ state: ApiState.LOADING })
    closeSocket()
    setSocketLoadInfo({ state: SocketState.IDLE })

    if (!gameId()) {
      const { response, error } = await newGameService({})
      if (error) {
        setPageLoadInfo({ state: ApiState.ERROR, error })
        return
      }
      setGameId(response.game.id)
      navigate(`/game/${gameId()}`)
    }

    setupSocket()
  }))

  /* Init */
  init()

  return (
    <>
      <Switch>
        <Match when={pageLoadInfo().state === ApiState.LOADING}>
          <PageLoader />
        </Match>
        <Match when={pageLoadInfo().state === ApiState.ERROR}>
          <AlertDialog open={pageLoadInfo().state === ApiState.ERROR}>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Something went wrong</AlertDialogTitle>
                <AlertDialogDescription>
                  {pageLoadInfo().error.message}
                  <Show when={pageLoadInfo().error.stack_trace}>
                    <Collapsible class='text-xs mt-1'>
                      <CollapsibleTrigger>Show detailed error</CollapsibleTrigger>
                      <CollapsibleContent>
                        <div>{pageLoadInfo().error.stack_trace}</div>
                      </CollapsibleContent>
                    </Collapsible>
                  </Show>
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogClose onClick={() => location.reload()}>Try again</AlertDialogClose>
                <AlertDialogAction onClick={() => navigate("/")}>Go to dashboard</AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        </Match>
        <Match when={pageLoadInfo().state === ApiState.LOADED}>
          {/* Actual page */}
          <div class={`w-full h-full bg-gradient-to-b from-gray-900 to-gray-950 flex flex-col overflow-hidden`}>

            {/* Game */}
            <Show when={resetGameView()}>
              <div class="grow overflow-auto">
                <Switch>
                  <Match when={game().type === GameType.polyssimo}>
                    <Polyssimo schema={schema()} defaultSettings={defaultSettings()} initGame={game() as any} sendMessage={sendMessage} ref={gameRef} />
                  </Match>
                  <Match when={game().type === GameType.isle_of_cats}>
                    <IsleOfCats schema={schema()} defaultSettings={defaultSettings()} initGame={game() as any} sendMessage={sendMessage} ref={gameRef} />
                  </Match>
                  <Match when={game().type === GameType.barenpark}>
                    <Barenpark schema={schema()} defaultSettings={defaultSettings()} initGame={game() as any} sendMessage={sendMessage} ref={gameRef} />
                  </Match>
                </Switch>
              </div>
            </Show>
          </div>

          {/* Socket Loading */}
          <Switch>
            <Match when={socketLoadInfo().state === SocketState.LOADING}>
              {/* Reconnecting */}
              <AlertDialog open={socketLoadInfo().state === SocketState.LOADING}>
                <AlertDialogContent style="box-shadow: none; border: none; background: none; outline: none" class="flex justify-center">
                  <Reconnecting />
                </AlertDialogContent>
              </AlertDialog>
            </Match>
            <Match when={!hideSocketErrorModal() && socketLoadInfo().state === SocketState.CLOSE}>
              {/* Socker error */}
              <AlertDialog open={!!socketLoadInfo().error}>
                <AlertDialogContent>
                  <AlertDialogHeader>
                    <AlertDialogTitle>
                      <div class='flex justify-between items-center'>
                        <div>Something went wrong</div>
                        <div><XIcon size={16} class='cursor-pointer' onClick={() => setHideSocketErrorModal(true)} /></div>
                      </div>
                    </AlertDialogTitle>
                    <AlertDialogDescription>
                      {socketLoadInfo().error.message}
                      <Show when={socketLoadInfo().error.stack_trace}>
                        <Collapsible class='text-xs mt-1'>
                          <CollapsibleTrigger>Show detailed error</CollapsibleTrigger>
                          <CollapsibleContent>
                            <div>{socketLoadInfo().error.stack_trace}</div>
                          </CollapsibleContent>
                        </Collapsible>
                      </Show>
                    </AlertDialogDescription>
                  </AlertDialogHeader>
                  <AlertDialogFooter>
                    <AlertDialogClose onClick={() => reconnect()}>Reconnect</AlertDialogClose>
                    <AlertDialogAction onClick={() => navigate("/")}>Go to dashboard</AlertDialogAction>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialog>
            </Match>
          </Switch>
        </Match>
      </Switch>
    </>
  )
};
