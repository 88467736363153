/* @refresh reload */
import { render } from 'solid-js/web'
import { Router, Route, useLocation, Navigate } from "@solidjs/router";
import { getCookie, setCookie } from "@polyomino/browser-utils/src/lib/utils"
import posthog from 'posthog-js'

import './tailwind.scss'
import './scrollbar.scss'
import './index.scss'

import { Layout } from './layouts/Layout';
import { Game } from './views/Game/Game';
import { NotFound } from './views/NotFound';
import { POSTHOG_HOST, POSTHOG_KEY } from './config';
import { createEffect, Suspense } from 'solid-js';
import { ToastList, ToastRegion } from './components/ui/toast';


posthog.init(POSTHOG_KEY,
    {
        api_host: POSTHOG_HOST,
        person_profiles: 'identified_only' // or 'always' to create profiles for anonymous users as well
    }
)

const root = document.getElementById('root')

const App = (props: any) => {
  console.log('INIT')
  const location = useLocation();  // Get the current location from the router

  // Track page views globally in one place
  createEffect(() => {
    console.log('on page view change')
    posthog.capture('$pageview', { path: location.pathname });
  });
  
  return (<Suspense>
    <Layout {...props}/>
    <ToastRegion>
      <ToastList />
    </ToastRegion>
    </Suspense>
  )
}


render(() => (
    <Router root={App}>
        <Route path="/" component={() => <Navigate href="/game"/>}/>
        <Route path="/game/:gameId?" component={Game} />
        <Route path="*paramName" component={NotFound} />
    </Router>
), root!)
