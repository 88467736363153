import { TileStyle } from "@/components/Tile/types";
import { GameType } from "./objects";

export const GameNameMap: {[key in GameType]: string} = {
  [GameType.polyssimo]: "Polyssimo",
  [GameType.isle_of_cats]: "Isle of cats",
  [GameType.barenpark]: "Bärenpark"
}

export const BOARD_STYLE: TileStyle = {
  color: "rgb(51, 51, 51)",
  borderRadius: 0.125,
  gap: 0.05
}

export const getPieceStyle = (color: string): TileStyle => {
  return {
    ...BOARD_STYLE,
    color
  }
}

export const getPossibilityStyle = (color: string, selected: boolean): TileStyle => {
  return {
    ...BOARD_STYLE,
    color,
    opacity: selected ? 0.7 : 0.5
  }
}

export const getRoomStyle = (color: string): TileStyle => {
  return {
    ...BOARD_STYLE,
    color,
    border: {color: "gray", width: 0.01}
  }
}

export const getResourceStyle = (color: string): TileStyle => {
  return {
    ...BOARD_STYLE,
    color,
    gap: 0.25,
    borderRadius: 1,
    border: {color: "black", width: 0.02}
  }
}

export const getExtensionStyle = (selected: boolean): TileStyle => {
  return {
    color: "red",
    borderRadius: 0.125,
    gap: 0.05,
    ...(selected ? {border: {color: "maroon", width: 0.05}} : {})
  }
}