import { PieceGeneric, PiecePlacementGeneric, Position } from "./objects";

export type RequestGeneric<T, D> = {
  id: string
  timestamp: number;
  type: T
  data: D
}

export enum RequestTypeGeneric {
  POSSIBILITIES = "POSSIBILITIES",
  MAKE_MOVE = "MAKE_MOVE",
  PASS_MOVE = "PASS_MOVE"
}

export type PosibilitiesRequestPayloadGeneric<P extends PieceGeneric> = {
  selected_piece: P
  selected_positions: Position[]
}

export type MakeMoveRequestPayloadGeneric<P extends PieceGeneric> = {
  piece_placement: PiecePlacementGeneric<P>
}
