import { HttpErrorPayload, SocketErrorPayload } from "./http";

export const enum ApiState {
  NOT_LOADED = "NOT_LOADED",
  LOADING = "LOADING",
  LOADED = "LOADED",
  ERROR = "ERROR"
}

export type ApiLoadInfo = {
  state: ApiState,
  error?: HttpErrorPayload
}

export enum SocketState {
  IDLE = "IDLE",
  LOADING = "LOADING",
  OPEN = "OPEN",
  CLOSE = "CLOSE"
}

export type SocketLoadInfo = {state: SocketState, error?: SocketErrorPayload}
