import { GameGeneric, PieceGeneric, PiecePlacementGeneric, PlayerGeneric, Position, Possibility } from "./objects";

export type ResponseGeneric<T, D> = {
  id: string
  timestamp: number;
  type: T
  data: D
}

export enum ResponseTypeGeneric {
  INIT = "INIT",
  GAME_STATE = "GAME_STATE",
  POSSIBILITIES = "POSSIBILITIES",
}

export type InitResponsePayloadGeneric<G extends GameGeneric<PlayerGeneric>> = {
  game: G
}

export type GameStateResponsePayloadGeneric<G extends GameGeneric<PlayerGeneric>> = {
  game: G
}

export type PossibilitiesResponsePayloadGeneric<P extends PieceGeneric> = {
  possibilities: Possibility[]
  final_placement: PiecePlacementGeneric<P>
}